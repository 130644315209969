import Vue from 'vue/dist/vue.esm';
import VeeValidate from 'vee-validate';
import AttoSelect from '../components/atto-select';
import axios from 'axios';
import _ from 'lodash';
import normalizeUrl from 'normalize-url';

let token = document.getElementsByName('csrf-token')[0].getAttribute('content');
axios.defaults.headers.common['X-CSRF-Token'] = token;
axios.defaults.headers.common['Accept'] = 'application/json';

Vue.use(VeeValidate);
Vue.component('atto-select', AttoSelect)

document.addEventListener('DOMContentLoaded', () => {
  $(".vue-form").each(function() {
    var data = $(this).data()

    new Vue({
      el: this,
      data: {
        forceFull: false,
        ...data,
        clickMode: null,
        model: {
          ...data.model
        }
      },
      mounted() {

        if(this.forceFull) {
          this.model.full_access = true;
        }

        $('.js-social').on('click', (e) => {
          window.dataLayer.push({
            event_action: "Social Login",
            event_category: "Registration Form",
            event: "form_activity",
            event_label: e.target.getAttribute('data-provider')
          })
          return true;
        })

        $(".close").on('click', (e) => {
          this.close()
        })

        $("body.-no-user").find(".-p").on('click', (e) => {
          this.clickMode = "register"
          e.preventDefault()
          $("body").addClass("-show-login")
          if($(e.target).hasClass("-p2")) {
            this.destination = window.location.toString()
          } else {
            this.destination = $(e.target).attr("href")
          }
          $("body").data('destination',this.destination)
          $("html, body").animate({
            scrollTop: 0
          })
          return false
        })
        $(".-force-full").on('click', (e) => {
          this.clickMode = "register"
          this.model.full_access = true
          this.forceFull = true
          e.preventDefault()
          $("body").addClass("-show-login")
          if($(e.target).hasClass("-p2")) {
            this.destination = window.location.toString()
          } else {
            this.destination = $(e.target).attr("href")
          }
          $("body").data('destination',this.destination)
          $("html, body").animate({
            scrollTop: 0
          })
          return false
        })
        $(".-adopt").on('click', (e) => {
          this.clickMode = "adopt"
          e.preventDefault()
          $("body").addClass("-show-login")
          if($(e.target).hasClass("-p2")) {
            this.destination = window.location.toString()
          } else {
            this.destination = $(e.target).attr("href")
          }
          this.model.competition = $(e.target).hasClass("-competition")
          
          $("body").data('destination',this.destination)
          $("html, body").animate({
            scrollTop: 0
          })
          return false
        })
        $(".js-meeting").on('click', (e) => {
          this.clickMode = "meeting"
          e.preventDefault()
          $("body").addClass("-show-login")
          this.destination = $(e.target).attr("href")
          $("body").data('destination',this.destination)
          $("html, body").animate({
            scrollTop: 0
          })
          return false
        })
        $(".js-download").on('click', (e) => {
          e.preventDefault()
          this.clickMode = "download"
          this.model.full_access = true
          this.forceFull = true
          $("body").addClass("-show-login")
          this.destination = $(e.target).attr("href")
          $("body").data('destination',this.destination)
          $("html, body").animate({
            scrollTop: 0
          })
          return false
        })
        $(".js-follow").on('click', (e) => {
          e.preventDefault()
          this.clickMode = "follow"
          this.model.full_access = true
          this.forceFull = true
          $("body").addClass("-show-login")
          this.destination = $(e.target).attr("href")

          $("body").data('destination',this.destination)
          $("html, body").animate({
            scrollTop: 0
          })
          return false
        })

        $(".js-mailing").on('click', (e) => {
          this.clickMode = "mailing"
          this.model.mailing_list = true
          e.preventDefault()
          $("body").addClass("-show-login")
          this.destination = $(e.target).attr("href")
          $("body").data('destination',this.destination)
          $("html, body").animate({
            scrollTop: 0
          })
          return false
        })
      },
      watch: {
        'clickMode': function(o, n) {
          console.log(o, n)
          if(!n && o) {
            window.dataLayer.push({
              event_action: "Form Open",
              event_category: "Registration Form",
              event: 'form_activity',
              event_label: o
            })
          }
        },
        'model.mailing_list': function(o, n) {
          if(this.model.mailing_list) {
            this.model.full_access = true
          }
        },
        'model.user_category_id': function(o, n) {
          if(this.model.user_category_id) {
            window.dataLayer.push({
              event_action: "Select User Category",
              event_category: "Registration Form",
              event: "form_activity",
              event_label: this.model.user_category_id
            })
          }
        },
        'mode': function(o, n) {
          window.dataLayer.push({
            event_action: "Change Mode",
            event_category: "Registration Form",
            event: "form_activity",
            event_label: n
          })
        }
      },
      methods: {
        onPassword(event) { 
          this.$validator.validate().then(result => {

            window.dataLayer.push({
              event_action: "Password Validation",
              event_category: "Registration Form",
              event: "form_activity",
              event_label: result ? 'success' : 'error',
              errors: this.errors.all().join(", ")
            })

            if(result) {
              axios({
                method: event.target.getAttribute('method'),
                url: event.target.action,
                data: {
                  email: this.model.email,
                  destination: this.destination
                }
              }).then((response) => {
                this.mode = "password_done"
              })
            }
          })
        },
        onLogin(event) { 
          this.$validator.validate().then(result => {

            window.dataLayer.push({
                event_action: "Login Validation",
              event_category: "Registration Form",
              event: "form_activity",
              event_label: result ? 'success' : 'error',
              errors: this.errors.all().join(", ")
            })

            if(result) {
              axios({
                method: event.target.getAttribute('method'),
                url: event.target.action,
                data: {
                  email: this.model.email,
                  password: this.model.password,
                  destination: this.destination
                }
              }).then((response) => {
                this.close("success")

                window.dataLayer.push({
                  event_action: "Login Success",
                  event_category: "Registration Form",
                  event: "form_activity"
                })

                try {
                  if(normalizeUrl(response.data.destination) == normalizeUrl(window.location.href)) {
                    window.location.reload(true)
                  } else {
                    window.location.assign(response.data.destination)
                  }
                } catch(e) {
                  window.location.assign(response.data.destination)
                }
              })
              .catch((error) => {
                console.log(error)

                window.dataLayer.push({
                  event_action: "Login Error",
                  event_category: "Registration Form",
                  event: "form_activity",
                  errors: Object.keys(error.response.data.errors).join(", ")
                })

                this.errors.clear();
                _.each(error.response.data.errors, (v, k) => {
                  _.each(v, i => this.errors.add({
                    field: k,
                    msg: i,
                    rule: "server"
                  }));
                });
              });
            }
          })
        },
        close(action = "close") {
          $("body").removeClass("-show-login")
          this.mode = "register"
          if(action == "close") {
            window.dataLayer.push({
              event_action: "Form Close",
              event_category: "Registration Form",
              event: "form_activity",
            })
          }
        },
        onSubmit(event) {

          

          this.$validator.validate().then(result => {        
            window.dataLayer.push({
              event_action: "Form Validate",
              event_category: "Registration Form",
              event: "form_activity",
              event_label: result ? 'success' : 'error',
              errors: this.errors.all().join(", ")
            })
            

            if(result) {

              var payload = {}
              payload[data.name] = this.model

              axios({
                method: event.target.getAttribute('method'),
                url: event.target.action,
                data: {
                  ...payload,
                  destination: this.destination,
                }
              }).then((response) => {
                //const newUrl = parse(response.data.url)
                //const currentUrl = parse(window.location)
                this.close("success")

                window.dataLayer.push({
                  event_action: "Form Success",
                  event_category: "Registration Form",
                  event: "form_activity"
                })

                if(response.data.adopted && this.clickMode != "follow") {
                  $("body").addClass("-show-follow")
                  $("body").data("destination", response.data.destination)
                } else {
                  try {
                    if(normalizeUrl(response.data.destination) == normalizeUrl(window.location.href)) {
                      window.location.reload(true)
                    } else {
                      window.location.assign(response.data.destination)
                    }
                  } catch(e) {
                    window.location.assign(response.data.destination)
                  }
                }

                // if(newUrl.hash.length > 2 && newUrl.hash == currentUrl.hash) {
                //   window.location.reload()
                // } else {
                //   window.location = response.data.url;
                // }
              }).catch((error) => {

                window.dataLayer.push({
                  event_action: "Form Error",
                  event_category: "Registration Form",
                  event: "form_activity",
                  errors: Object.keys(error.response.data.errors).join(", ")
                })

                this.errors.clear();
                _.each(error.response.data.errors, (v, k) => {
                  _.each(v, i => this.errors.add({
                    field: k,
                    msg: i,
                    rule: "server"
                  }));
                });
              });
            }
          });
          return;
        },
      },
    });
  })
})