<template>
  <div>

    <v-select 
      ref="vueselect"
      :label="label"
      :name="name"
      :multiple="multiple"
      :data-vv-name="name"
      v-model="selected"
      v-validate="{required: required}"
      :options="options"
      :placeholder="placeholder">
    </v-select>
  </div>
</template>

<script>
import Vue from 'vue/dist/vue.esm';
import VueSelect from 'vue-select'
Vue.component('v-select', VueSelect)
export default {
  inject: {
    $validator: '$validator'
  },
  data() {
    return {
      selected: null,
    }
  },
  watch: {
    'selected': function(oldValue, newValue) {
      if(this.multiple) {
        this.$emit('input',this.selected ? this.selected.map(o => o[this.idField]) : null)

      } else {
        this.$emit('input',this.selected ? this.selected[this.idField] : null)
      }
    },
  },
  mounted() {
    this.selected = this.options.find(v => v.id == this.value)
    if(this.initial) {
      this.selected = _.map(this.initial, o => this.options.find(v => v.id == o))
    }
  },
  props: {
    name: String,
    label: {
      type: String,
      default: "name"
    },
    idField: {
      type: String,
      default: "id"
    },
    options: Array,
    value: null,
    required: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    initial: Array
  }
}
</script>
